import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import './HistoryTable.css';  // CSSファイルをインポート
import homeIcon from '../assets/home-icon.png';  // ホームアイコンの画像をインポート
import reloadIcon from '../assets/reload-icon.png';  // リロードアイコンの画像をインポート
import calendarIcon from '../assets/calendar-icon.png';  // カレンダーアイコンの画像をインポート

function HistoryTable() {
  const [timestamps, setTimestamps] = useState([]);
  const [filteredTimestamps, setFilteredTimestamps] = useState([]);
  const [selectedDate, setSelectedDate] = useState(localStorage.getItem('selectedDate') || '');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dateButtonRef = useRef(null);  // 日付変更ボタンの参照

  useEffect(() => {
    const fetchTimestamps = async () => {
      try {
        console.log('Fetching timestamps...');
        const response = await axios.get(`${config.API_BASE_URL}/history/timestamps`);
        console.log('Timestamps fetched:', response.data);
        const uniqueDates = [...new Set(response.data.map(ts => ts.slice(0, 8)))].sort().reverse(); // 重複を排除し、降順に並べる
        setTimestamps(uniqueDates);

        const latestTimestamp = response.data.sort().reverse()[0];
        const latestDate = latestTimestamp.slice(0, 8);

        if (!selectedDate) {
          setSelectedDate(latestDate);
          localStorage.setItem('selectedDate', latestDate);
          await fetchDetails(latestDate);
        } else {
          await fetchDetails(selectedDate);
        }
      } catch (error) {
        console.error('Failed to fetch timestamps', error);
      }
    };

    fetchTimestamps();
  }, [selectedDate]);

  const fetchDetails = async (date) => {
    setLoading(true);  // ローディング状態を設定
    try {
      console.log(`Fetching details for date: ${date}`);
      const response = await axios.get(`${config.API_BASE_URL}/history/details/${date}`);
      console.log('Details fetched:', response.data);

      // nullをフィルタリングして除外し、時刻を降順にソート
      setFilteredTimestamps(response.data.filter(detail => detail !== null).sort((a, b) => b.time.localeCompare(a.time)));
    } catch (error) {
      console.error('Failed to fetch details', error);
      setFilteredTimestamps([]); // エラー時には空のリストを設定
    } finally {
      setLoading(false);  // ローディング状態を解除
    }
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setIsDropdownOpen(false);
    localStorage.setItem('selectedDate', date);
    await fetchDetails(date);
  };

  const handleReload = async () => {
    try {
      console.log('Reloading timestamps...');
      const response = await axios.get(`${config.API_BASE_URL}/history/timestamps`);
      console.log('Timestamps reloaded:', response.data);
      const uniqueDates = [...new Set(response.data.map(ts => ts.slice(0, 8)))].sort().reverse(); // 重複を排除し、降順に並べる
      setTimestamps(uniqueDates);
      const latestTimestamp = response.data.sort().reverse()[0];
      const latestDate = latestTimestamp.slice(0, 8);
      setSelectedDate(latestDate);
      localStorage.setItem('selectedDate', latestDate);
      await fetchDetails(latestDate);
    } catch (error) {
      console.error('Failed to reload timestamps', error);
    }
  };

  const formatTime = (timeString) => {
    return `${timeString.slice(0, 2)}:${timeString.slice(2, 4)}`;
  };

  const handleRowClick = (timestamp, time) => {
    console.log(`Navigating to view: ${timestamp}${time}`);
    navigate(`/view/${timestamp}${time}`);
  };

  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString.slice(0, 4), dateString.slice(4, 6) - 1, dateString.slice(6, 8));
    return ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
  };

  const formattedSelectedDate = `${selectedDate.slice(0, 4)}/${selectedDate.slice(4, 6)}/${selectedDate.slice(6, 8)} (${getDayOfWeek(selectedDate)})`;

  return (
    <div className="history-container">
      <div className="history-header">
        <div className="title-container">
          <img src={homeIcon} alt="Home" />
          <h1>ダメージ検出試験</h1>
        </div>
        <button onClick={handleReload} className="reload-button">
          <img src={reloadIcon} alt="Reload" />
          最新
        </button>
      </div>
      <div className="history-date">
        <img src={calendarIcon} alt="Calendar" />
        <p>{formattedSelectedDate}</p>
        <button ref={dateButtonRef} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>日付変更</button>
        {isDropdownOpen && (
          <div className="dropdown-overlay" style={{ top: dateButtonRef.current?.getBoundingClientRect().bottom + window.scrollY, left: dateButtonRef.current?.getBoundingClientRect().left }}>
            <ul className="uk-nav uk-dropdown-nav">
              <li className="uk-active"><a href="#">日付を選択してください</a></li>
              {timestamps.map((timestamp, index) => {
                const date = timestamp;
                return (
                  <li key={index}>
                    <a href="#" onClick={() => handleDateChange(date)}>
                      {`${date.slice(0, 4)}/${date.slice(4, 6)}/${date.slice(6, 8)} (${getDayOfWeek(date)})`}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      <table className="history-table">
        <thead>
          <tr>
            <th>時刻</th>
            <th>コンテナ</th>
            <th>ダメージ</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="3">Loading...</td>
            </tr>
          ) : (
            filteredTimestamps.length > 0 ? (
              filteredTimestamps.map(detail => (
                <tr key={detail.time} onClick={() => handleRowClick(selectedDate, detail.time)}>
                  <td>{formatTime(detail.time)}</td>
                  <td>{detail.container}</td>
                  <td>{detail.damage}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No data available</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default HistoryTable;
