import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import homeIcon from '../assets/home-icon.png';
import './Login.css';  // CSSファイルをインポート

function Login({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      console.log(`Login attempt: username=${username}, password=${password}`);
      const response = await axios.post(`${config.API_BASE_URL}/auth/login`, { username, password });
      console.log('Login response:', response.data);
      if (response.data.success) {
        console.log('Response data is an object:', response.data);
        console.log('Login successful');
        setIsAuthenticated(true);
        localStorage.setItem('token', response.data.token);  // トークンを保存
        console.log('Navigating to /view/latest');
        navigate('/view/latest');
      } else {
        console.log('Invalid credentials');
        setError('Invalid credentials');
      }
    } catch (error) {
      console.error('Login failed:', error.response || error.message);
      setError('Login failed. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <img src={homeIcon} alt="Home" />
        <h1>ダメージ検出試験</h1>
      </div>
      <form onSubmit={handleLogin} className="login-form">
        <p>ユーザーIDとパスワードを入力してください</p>
        <input
          type="text"
          placeholder="ユーザーID"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="パスワード"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">ログイン</button>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
}

export default Login;
