import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import config from '../config';
import './ImageViewer.css';
import homeIcon from '../assets/home-icon.png';
import reloadIcon from '../assets/reload-icon.png';
import clockIcon from '../assets/clock-icon.png';
const isoTable = require('../isotable.json');

function ImageViewer() {
  const { timestamp } = useParams();
  const [details, setDetails] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [damageDetails, setDamageDetails] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLatestTimestamp = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/history/latest-timestamp`);
        return response.data.timestamp;
      } catch (error) {
        console.error('Failed to fetch latest timestamp', error);
        return null;
      }
    };

    const fetchDetails = async (timestampToFetch) => {
      try {
        console.log(`Fetching details for timestamp: ${timestampToFetch}`);
        const response = await axios.get(`${config.API_BASE_URL}/history/folder-details/${timestampToFetch}`);
        console.log('Details fetched:', response.data);
        setDetails(response.data);

        const folderPath = `${config.API_BASE_URL}/history/${timestampToFetch}`;
        try {
          const damageResponse = await axios.get(`${folderPath}/Damage.json`);
          if (damageResponse.headers['content-type'].includes('application/json')) {
            setDamageDetails(damageResponse.data);
          } else {
            console.error('Unexpected response format for Damage.json:', damageResponse.data);
            setDamageDetails({
              anomaly_prob: 'No Data',
              loc_code: 'No Data',
              distances: 'No Data',
              length: 'No Data',
              failed_pcd: 'No Data',
            }); // ダミーデータ
          }
        } catch (error) {
          console.error('Failed to fetch Damage.json', error);
          setDamageDetails({
            anomaly_prob: 'No Data',
            loc_code: 'No Data',
            distances: 'No Data',
            length: 'No Data',
            failed_pcd: 'No Data',
          }); // ダミーデータ
        }
      } catch (error) {
        console.error('Failed to fetch folder details', error);
      }
    };

    if (timestamp === 'latest') {
      fetchLatestTimestamp().then(latestTimestamp => {
        if (latestTimestamp) {
          navigate(`/view/${latestTimestamp}`);
          fetchDetails(latestTimestamp);
        }
      });
    } else {
      fetchDetails(timestamp);
    }
  }, [timestamp, navigate]);

  const handleLatest = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/history/latest-timestamp`);
      const latestTimestamp = response.data.timestamp;
      navigate(`/view/${latestTimestamp}`);
    } catch (error) {
      console.error('Failed to fetch latest timestamp', error);
    }
  };

  if (!details) {
    return <div>Loading...</div>;
  }

  const navigateToHistory = () => {
    navigate('/history');
  };

  const formattedDate = `${timestamp.slice(0, 4)}/${timestamp.slice(4, 6)}/${timestamp.slice(6, 8)} (${['日', '月', '火', '水', '木', '金', '土'][new Date(timestamp.slice(0, 4), timestamp.slice(4, 6) - 1, timestamp.slice(6, 8)).getDay()]}) ${timestamp.slice(8, 10)}:${timestamp.slice(10, 12)}`;
  const size = details.size;
  const lengthCode = isoTable.Length[size.charAt(0)] || '--';
  const containerCode = isoTable.Container[size.slice(-2)] || '--';
  var heightCode = isoTable.Height[size.charAt(1)] || '--';
  if(heightCode !== '--'){
    heightCode = heightCode + '"'
  }
  const containerNumber = details.container ?
  `${details.container.slice(0, 4)} ${details.container.slice(4)} (${size} / ${lengthCode} ${containerCode} ${heightCode})` 
  : 'コンテナ番号不明';
  var showLocCode = damageDetails.loc_code===';;' ? 'No Damage' : damageDetails.loc_code;

  const handleThumbnailClick = (image, index) => {
    if (index === 0) {
      setSelectedImage(image);
    }
    if (index === 4) {
      setSelectedImage(image);
    }
    if (index === 5) {
      setSelectedImage(image);
    }
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
  };

  return (
    <div className="image-viewer-container">
      <div className="image-viewer-header">
        <div className="title-container">
          <img src={homeIcon} alt="Home" />
          <h1>ダメージ検出試験</h1>
        </div>
        <button onClick={handleLatest}>
          <img src={reloadIcon} alt="Reload" />
          最新
        </button>
      </div>
      <div className="image-viewer-date-time">
        <img src={clockIcon} alt="Clock" />
        <p>{formattedDate}</p>
        <button onClick={navigateToHistory}>履歴</button>
      </div>
      <div className="image-viewer-container-number">
        {containerNumber}
      </div>
      <div className="image-viewer-damage">
        <div className="image-viewer-damage-label">ダメージ</div>
        <div className="image-viewer-damage-value">{showLocCode}</div>
      </div>
      <div className="image-viewer-thumbnails">
        {details.images.map((image, index) => (
          <img key={index} src={`${config.API_BASE_URL}/history/${timestamp}/${image}`} alt={image} onClick={() => handleThumbnailClick(image, index)} />
        ))}
      </div>
      <div className="image-viewer-damage-details">
        <p><strong>Anomaly Prob:</strong> {damageDetails.anomaly_prob}</p>
        <p><strong>Loc Code:</strong> {damageDetails.loc_code}</p>
        <p><strong>Distances:</strong> {damageDetails.distances}</p>
        <p><strong>Length:</strong> {damageDetails.length}</p>
        <p><strong>Failed PCD:</strong> {damageDetails.failed_pcd}</p>
      </div>
      {selectedImage && (
        <div className="image-viewer-popup">
          <img src={`${config.API_BASE_URL}/history/${timestamp}/${selectedImage}`} alt={selectedImage} />
          <div className="close-button" onClick={handleClosePopup}>&times;</div>
          <div className="container-number">{containerNumber}</div>
        </div>
      )}
    </div>
  );
}

export default ImageViewer;
